@use "index" as *;

.staff {
	padding: 0;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem;
	padding: 2rem;
	margin: 2rem auto;
	max-width: 1200px;

	@media (max-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 640px) {
		grid-template-columns: 1fr;
	}

	li {
		background: #fff;
		border-radius: 8px;
		overflow: hidden;
		box-shadow: 0 2px 10px rgba(0,0,0,0.1);
		transition: transform 0.2s ease;
		padding: 2rem;
		text-align: center;

		&:hover {
			transform: translateY(-5px);
		}
	}

	.square-image {
		width: 180px;
		height: 180px;
		margin: 0 auto 1.5rem;

		img {
			border-radius: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: static;
		}
	}

	.name {
		font-size: 1.4rem;
		margin: 0 0 0.5rem;
		font-weight: 600;
		color: #333;
		text-align: center;

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: #00a4ca;
			}
		}
	}

	.position {
		font-size: 1.05rem;
		color: #666;
		font-weight: 500;
		margin-bottom: 1rem;
		text-align: center;
	}

	.staff-blurb {
		font-size: 0.95rem;
		line-height: 1.6;
		color: #666;
		text-align: left;
		margin-top: 1rem;
		
		p {
			margin: 0;
		}
	}
}

.leadership {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 2rem;
	padding: 2rem;
	list-style: none;
	margin: 2rem auto;
	max-width: 1400px;

	@media (max-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
		max-width: 800px;
	}

	@media (max-width: 640px) {
		grid-template-columns: 1fr;
		max-width: 400px;
	}
}

.leader-card {
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 10px rgba(0,0,0,0.1);
	transition: transform 0.2s ease;

	&:hover {
		transform: translateY(-5px);
	}

	.leader-image {
		width: 100%;
		height: 300px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.leader-info {
		padding: 1.5rem;
	}

	.leader-name {
		font-size: 1.5rem;
		margin: 0 0 0.5rem;
		color: #333;
		text-align: center;
	}

	.leader-position {
		padding: 0;
		font-size: 1.05rem;
		color: #666;
		margin: 0 0 1rem;
		font-weight: 500;
		text-align: center;
	}

	.leader-blurb {
		font-size: 1rem;
		line-height: 1.6;
		color: #333;
		margin: 0;
		padding: 0;

	}
}