@use "index" as *;

.nav-open nav {
	border-bottom: none;
	padding: 0;
	a {
		display: none;
		
		&.nav-toggle {
			display: inline-block;
		}
	}

	@media #{$mid-point} {
		border: 0;
		padding: 0 20px;

		a {
			display: inline-block;
		}
	}
}

// Mobile menu container styles
.mobile-menu-container {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background: rgba(43, 43, 64, 0.95);
	z-index: 999;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(2px);
	
	a {
		display: block;
		text-align: center;
		padding: 15px 20px;
		color: rgba(255, 255, 255, 0.8);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		text-transform: uppercase;
		font-size: 1.0em;
		transition: .2s ease-in-out;
		
		&:hover {
			background: rgba(255, 255, 255, 0.1);
			color: #fff;
		}
		
		&.active {
			color: #fff;
			font-weight: bold;
		}
		
		&:last-child {
			border-bottom: none;
		}
	}
	
	@media #{$mid-point} {
		display: none !important;
	}
}

// Show mobile menu when nav is open
.nav-open .mobile-menu-container {
	display: block;
}

nav {
	text-transform: uppercase;
	font-size: 1.0em;
	width: 100%;
	display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

	@media #{$mid-point} {
		text-align: right;
        padding: 0;
        position: relative;
        top: auto;
        right: auto;
	}

	a {
		margin: 0 3px;
		padding: 10px;
		border-bottom: 1px solid rgba(255,255,255,0);
		color: rgba(255,255,255,.8);
		transition: .2s ease-in-out;
		display: none;

		@media #{$mid-point} {
			display: inline-block;
		}

		&.nav-toggle {
			display: inline-block;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 2.2em;
			border: 0;
			padding: 10px 20px;
			z-index: 100;
			line-height: 1;

			@media #{$mid-point} {
				display: none;
			}

			&:hover {
				border: 0;
			}
		}
	}

	a:hover {
		border-bottom: 1px solid rgba(255,255,255,.3);
		color: #fff;
	}

	@media #{$mid-point} {
		a.highlight {
			border: 1px #ccc solid;
			border-radius: 5px;

			&:hover {
				background: #fff;
				color: $brand-color;
			}
		}
	}

	a.active {
		color: #fff;
	}
}
