@use "index" as *;
@use "sass:color";

body {
    background-position: center -200px !important; 
    background-size: cover !important; 
    background-attachment: fixed !important;
    @media (max-width: 768px) {
        background-position: center -150px !important;
}
}

.container, .text-container {
	margin: 0 auto;
	position: relative;
	padding: 0 20px;
}

.text-container {
	max-width: 750px;
    background-color: rgba(43, 43, 64, 0.3);
    border-radius: 4px;
    backdrop-filter: blur(5px);
    padding: 1em 2em 1em 2em;
    @media (max-width: 768px) {
        border-radius: 0px; /* Reduces text size by 40% */
    }
    
}

.container {
	max-width: 1140px;

	&.max-container {
		max-width: 100%;
		padding: 0;
	}

	video {
		width: 100%;
		max-width: 800px;  // Limit maximum width
		height: auto;
		display: block;
		margin: 2rem auto;  // Center the video with margin
		border-radius: 8px;  // Slightly rounded corners
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  // Subtle shadow
		aspect-ratio: 16 / 9;  // Maintain video aspect ratio
		background: #f5f5f5;  // Light background while loading
		object-fit: cover;  // Ensure video fills container properly
	}
}

header {
	color: #fff;
	padding: 20px 0; // Adjusted padding to be vertical only
	background: $brand-color; /* Old browsers */
    background: transparent;
    display: flex;
	position: relative;
	z-index: 1000; // Ensure header stays above mobile menu


/* Create a gradient container for the blur effect */
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(43, 43, 64, 0.3); /* Background color with opacity */
        backdrop-filter: blur(15px); /* Apply the blur effect */
        -webkit-backdrop-filter: blur(15px); /* For Safari support */
        -webkit-mask-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 20%, /* No blur near the logo */
            rgba(0, 0, 0, 1) 40% /* Full blur near the links */
        );
        mask-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 20%, /* No blur near the logo */
            rgba(0, 0, 0, 1) 40% /* Full blur near the links */
        );
        z-index: -1; /* Place the pseudo-element behind the content */
    }

	
	.container {
        display: flex;
        justify-content: space-between;
        align-items: center;
		// max-width: 1140px;
        padding: 0; // Remove horizontal padding to allow hamburger to align with edge
        width: 100%;
        min-height: 60px; // Ensure consistent height for the header
    }
	a {
		color: #fff;
		text-decoration: none;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}

	.company-name {
		font-size: 1.7em;
		display: flex;
		align-items: center;
		flex-shrink: 0;
		margin-right: auto;
		padding: 10px 20px; // Add horizontal padding to the logo container

		a {
			display: inline-flex;
			align-items: center;
		}

		img {
			display: block;
			width: auto;
            height: 30px; // Set a default height for desktop
            transition: height 0.3s ease; // Add smooth transition

            @media (max-width: 768px) {
                height: 25px; // Smaller height for mobile devices
            }
		}
	}
} 

.content {
	background: #fff;
	padding: 20px 0 0 0;
	position: relative;

	h2 {
		text-align: center;
		font-weight: 600;
		color: #333;
		padding: 20px 0;
	}
}
.content p {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 3em;

    @media (max-width: 768px) {
        text-align: center;
    }
}

.screenshot{
	max-width: 100%;
	height: auto;
	display: block;
	box-shadow: 0 1px 0 #ccc, 0 1px 0 1px #eee;
	border-radius: 4px;
	margin-left: auto;
	margin-right: auto;
	padding: 20px 0 0 0;
	position: relative;
}

section {
	padding: 20px 0;
}

section + section {
	padding-top: 0;
}

.subtext {
    margin-top: 10px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 100%; 
    }
}



.cta {
	margin: 60px 0;
}

.page h2 {
	text-align: center;
}

blockquote {
	padding: 18px 25px;
	margin: 0;
	quotes: "\201C""\201D""\2018""\2019";
	font-style: italic;

	.author {
		display: block;
		font-weight: bold;
		margin: 10px 0 0 0;
		font-size: .85em;
		font-style: normal;
	}

	p {
		display: inline;
	}
}

blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

.square-image {
	width: 150px;
	height: 150px;
	overflow: hidden;
	margin: 25px auto 0 auto;
	position: relative;
	border-radius: 200px;

	img {
		position: absolute;
		left: -1000%;
		right: -1000%;
		top: -1000%;
		bottom: -1000%;
		margin: auto;
		width: 300px;
	}
}

.page {
	margin-bottom: 0;
	padding-bottom: 80px;
}

.center-text {
	text-align: center;
}

.editor-link {
 	display: none;
	margin-top: 0;
	.btn {
		border: 0;
		border-radius: 2px;
		width: 100%;
		max-width: 500px;
		box-sizing: border-box;
		font-size: 2rem;
		text-decoration: none;
		padding: 10px 15px;
		margin: 0;
		font-size: 18px;
		cursor: pointer;
		background-color: #f7e064;
		color: #333;
		box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

		&:hover {
			background-color: #f4d525;
			color: #333;
		}
	}

}

.flex {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.image-column {
    flex: 0 0 45%;

    .screenshot {
        margin: 0;
        padding: 0;
        background: none;
        box-shadow: none;
    }
}

.text-column {
    flex: 1;
}

.value-item {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}

.value-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.value-icon {
    flex-shrink: 0;
    
    img {
        width: 48px;
        height: 48px;
    }
}

.service-icon {
    flex-shrink: 0;
    text-align: center;
    
    img {
        width: 96px;
        height: 96px;
    }
}

.service-text h2 {
    font-size: 1.5em;
}


.value-content p {
    margin: 0;
    font-size: 1.1em;
    line-height: 1.6;
}

.service-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    padding: 20px;
    margin: 2rem 2rem;
    list-style: none;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .stat-card {
        padding: 2rem;
        text-align: center;
        background: #6B8C9C;  // Base blue-grey color (10% lighter)
        transition: background-color 0.3s ease;
        border-radius: 4px;
        &:nth-child(2) {
            background: #90A4AE;  // Lighter blue-grey
        }

        &:nth-child(3) {
            background: #B0BEC5;  // Lightest blue-grey
        }
    }

    .stat-metric {
        font-size: 2.5rem;
        font-weight: 700;
        color: #333;
        margin-bottom: 0.5rem;
    }

    .stat-name {
        font-size: 1.2rem;
        font-weight: 500;
        color: #333;
        margin-bottom: 1rem;
    }

    .stat-description {
        font-size: 1rem;
        line-height: 1.5;
        color: #333;
        max-width: 300px;
        margin: 0 auto;
    }
}


.services-grid {
	padding: 0;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem;
	padding: 2rem;
	margin: 2rem auto;
	max-width: 1200px;
}

@media (max-width: 1024px) {
	.services-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 640px) {
	.services-grid {
		grid-template-columns: 1fr;
	}
}

.service-card {
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 10px rgba(0,0,0,0.1);
	transition: transform 0.2s ease;
	padding: 1rem;
	text-align: center;
}

.service-card:hover {
	transform: translateY(-5px);
}

.service-title {
	font-size: 1.6rem;
	margin: 1rem 0.5rem;
	font-weight: 600;
	color: #333;
}

.service-description {
	font-size: 1.05rem;
    font-weight: 200;
	line-height: 1.6;
	color: #666;
	text-align: left;
	margin: 1.2rem;
}

.industries-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 2rem;

    .industry-sector {

     

        > h3 {  // Sector headers (PRIVATE SECTOR, PUBLIC SECTOR)
            text-align: center;
            font-size: 2rem;
            color: #F15A29;  // Orange color for sector headers
            // margin: 2rem 0;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}

.sector-content {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: auto;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}

.sector-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 8px;
    flex-shrink: 0;
    align-self: center;

    &.left-image {
        order: -1;
        margin-right: 2rem;
    }

    &.right-image {
        order: 1;
        margin-left: 2rem;
    }

    @media (max-width: 768px) {
        order: -1 !important;
        margin: 1rem auto !important;
    }
}

.industries-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0;
    list-style: none;
    flex: 1;
    align-self: center;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 100%;
    }
}

.industry-card {
    text-align: center;
    padding: 1rem;

    h4 {  // Industry names (Financial Services, etc.)
        font-size: 1.5rem;
        margin: 0 0 1rem;
        color: #333;
        font-weight: 600;
    }

    p {  // Industry descriptions
        font-size: 1rem;
        line-height: 1.6;
        color: #666;
        max-width: 400px;
        margin: 0 auto;
        padding: 0;
    }
}

// Add styles for the intro text
.industries-container > p {
    text-align: center;
    margin: 1rem auto 3rem;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
}

@media (max-width: 768px) {
    .sector-image {
        order: -1 !important; // Always show image first on mobile
        margin: 1rem auto;
    }
}

.customer-stories {
    padding: 1rem 2rem;
    background: #f9f9f9;
    
    h2 {
        text-align: center;
        margin-bottom: 1rem;
    }
}

.testimonials {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.testimonial-card {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    .company-logo {
        width: auto;
        height: 60px;
        margin-bottom: 1.5rem;
    }
    
    blockquote {
        margin: 0;
        padding: 10px;
        border: none;
        font-style: italic;
        color: #666;
        
        &:before {
            display: none; // Remove the default quote marks
        }
        
        p {
            padding: 0;
            text-indent: 0;
            font-size: 1.1rem;
            line-height: 1.6;
            margin: 0 0 1rem;
        }
        
        cite {
            display: block;
            margin-top: 5px;
            font-style: normal;
            font-weight: 600;
            color: #333;
            font-size: 0.9rem;
        }
    }
}

.hero {
    min-height: 30vh;  // Minimum height of 70% of viewport height
    display: flex;
    align-items: center;
    padding: 4rem 0;  // Add padding to prevent content from touching top/bottom
    
    .container {
        width: 100%;
    }
}

.team-testimonials {
    padding: 1rem 2rem;
    background: #fff;
    max-width: 1200px;
    margin: 0 auto;
    
    h2 {
        text-align: center;
        margin-bottom: 3rem;
        color: #333;
        font-size: 2.5rem;
    }
}

.testimonials-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
}

.team-testimonial-card {
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 1rem;
    
    &:nth-child(even) {
        flex-direction: row-reverse;
    }
    
    @media (max-width: 768px) {
        flex-direction: column !important;
        text-align: center;
        gap: 1rem;
    }
    
    .testimonial-image {
        width: 300px;
        height: 300px;
        flex-shrink: 0;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 4px 12px rgba(0,0,0,0.1);
        
        @media (max-width: 768px) {
            width: 200px;
            height: 200px;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    blockquote {
        margin: 0;
        padding: 0;
        border: none;
        flex: 1;
        
        &:before {
            display: none; // Remove default quote mark
        }
        
        cite {
            display: block;
            font-style: normal;
            font-weight: 600;
            color: #F15A29;
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }
        
        p {
            padding: 0;
            text-indent: 0;
            font-size: 1.2rem;
            line-height: 1.8;
            color: #666;
            margin: 0;
            font-style: italic;
            
            &:before {
                content: "\201C"; // Opening quote mark
                color: #ccc;
                font-size: 2em;
                line-height: 0.1em;
                margin-right: 0.25em;
                vertical-align: -0.2em;
            }
            
            &:after {
                content: "\201D"; // Closing quote mark
                color: #ccc;
                font-size: 2em;
                line-height: 0.1em;
                margin-left: 0.25em;
                vertical-align: -0.2em;
            }
        }
    }
}

.careers-benefits {
    padding: 4rem 2rem;
    text-align: center;
    background: #f9f9f9;
    
    h2 {
        margin-bottom: 1.5rem;
        color: #333;
        font-size: 2.5rem;
    }
    
    p {
        max-width: 800px;
        margin: 0 auto;
        font-size: 1.2rem;
        line-height: 1.8;
        color: #666;
    }
}
.cta-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #2E75B7;  // Blue color from the image
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
    text-align: center;
    margin: 20px auto;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: color.adjust(#2E75B7, $lightness: -10%);
    }
}

.button-container {
    text-align: center;
    margin: 30px 0;

    h2 {
        padding: 0;  // Override existing h2 padding
        
        a {
            @extend .cta-button;
        }
    }
}

.careers-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
    margin: 2rem auto;
    max-width: 1200px;
    padding: 0 2rem;
    align-items: start; /* Ensure top alignment */

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

.form-group {
    margin-bottom: 1.5rem;

    label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #333;
    }

    input[type="text"],
    input[type="email"],
    input[type="file"],
    select,
    textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        font-family: inherit;
        font-size: 1rem;
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
        
        &:focus {
            outline: none;
            border-color: #2E75B7;
            box-shadow: 0 0 0 2px rgba(46, 117, 183, 0.2);
        }
    }

    input[type="file"] {
        padding: 0.5rem;
        background-color: #f9f9f9;
        cursor: pointer;
    }

    small {
        display: block;
        color: #666;
        margin-top: 0.25rem;
        font-size: 0.85rem;
    }

    select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 1em;
        padding-right: 2.5rem;
    }
}

.open-positions {
    padding-top: 0;
    
    h2 {
        margin-bottom: 1rem;
    }
    
    p {
        margin-bottom: 1rem;
    }
}

.jobs-grid {
    margin-top: 1rem;
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr;
}

.job-card {
    background: #ffffff;
    padding: 1.25rem 1.5rem;
    border-radius: 4px;
    transition: all 0.25s ease;
    border: 1px solid #eee;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 3px rgba(0,0,0,0.04);
    margin-bottom: 0;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 3px 8px rgba(0,0,0,0.08);
        border-color: #ddd;
    }

    .job-title {
        margin-bottom: 0.6rem;
        
        a {
            color: #2E75B7;
            text-decoration: none;
            font-size: 1.2rem;
            font-weight: 600;
            position: relative;
            display: inline-flex;
            align-items: center;
            transition: color 0.2s ease;
            
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -3px;
                left: 0;
                background-color: #2E75B7;
                transform: scaleX(0);
                transform-origin: bottom right;
                transition: transform 0.3s ease;
            }
            
            &:before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 6px;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%232E75B7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'%3e%3c/path%3e%3cpolyline points='15 3 21 3 21 9'%3e%3c/polyline%3e%3cline x1='10' y1='14' x2='21' y2='3'%3e%3c/line%3e%3c/svg%3e");
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.7;
                transition: opacity 0.2s ease;
            }
            
            &:hover {
                color: color.adjust(#2E75B7, $lightness: -15%);
                
                &:after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
                
                &:before {
                    opacity: 1;
                }
            }
        }
    }

    p {
        padding: 0;
        text-align: left !important;
        color: #666;
        margin-bottom: 0;
        line-height: 1.4;
        font-size: 0.95rem;
    }
}

.application-form {
    h2 {
        margin-bottom: 1.5rem;
    }

    p {
        margin-bottom: 2rem;
        color: #666;
    }

    .contact-form {
        margin: 0;
        padding: 0;
    }

    .form-group {
        margin-bottom: 1.5rem;
    }

    .button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #2E75B7;  // Blue color from the image
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
    text-align: center;
    margin: 20px auto;
    border: none;
    cursor: pointer;

        &:hover {
            background-color: color.adjust(#2E75B7, $lightness: -10%);
            transform: translateY(-2px);
        }

        &:active {
            transform: translateY(0);
        }
    }

    .g-recaptcha {
        margin: 1rem 0;
    }
}