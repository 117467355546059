@use "index" as *;
@use "sass:color";

/* Ensure all elements use border-box sizing */
.contact-container * {
    box-sizing: border-box;
}

.contact-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
    
    @media (max-width: 768px) {
        padding: 30px 15px;
        width: 90%;
    }
}

.contact-heading {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 40px;
    color: #333;
    
    @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 30px;
    }
}

.contact-box {
	@extend %flexbox;
	@include flex-flow(wrap);
	max-width: 1000px;
	margin: 0 auto;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    overflow: hidden;
    
    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        border-radius: 6px;
    }

	form {
		width: 100%
	}

	p {
		margin: 0;
	}

	.contact-details {
		font-size: .9em;
	}
}

.contact-image {
    padding: 30px;
    text-align: center;
	width: 100%;
    
    .profile-image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 15px;
    }
    
    h3 {
        margin: 10px 0 5px;
        font-size: 1.2rem;
        font-weight: 600;
    }
    
    p {
        margin: 0 0 15px;
        font-size: 0.9rem;
        color: #666;
    }
    
    .linkedin-link {
        display: inline-block;
        margin-bottom: 20px;
    }
    
    .linkedin-icon {
        height: 30px;
    }
    
    .contact-description-inner {
        margin-top: 25px;
        
        p {
            font-size: 0.95rem;
            line-height: 1.6;
            color: #666;
            text-align: center;
        }
    }
}

.contact-form, .contact-details, .contact-image {
	@media #{$desktop} {
		-webkit-flex: 1;
		flex: 1;
	}
}

.contact-description {
    max-width: 800px;
    margin: 40px auto 0;
    text-align: center;
    
    p {
        font-size: 1.1rem;
        line-height: 1.6;
        color: #666;
    }
}

/* Add styling for form inputs */
.contact-form {
    padding: 30px;
    
    .form-group {
        margin-bottom: 20px;
        text-align: left;
        
        label {
            display: block;
            margin-bottom: 8px;
            font-weight: 500;
            color: #333;
            
            .required {
                color: #F15A29;
            }
        }
    }
    
    .consent-group {
        display: flex;
        align-items: start;
		width: 100%;
        
        input[type="checkbox"] {
            margin-top: 4px;
            margin-right: 10px;
            flex-shrink: 0;
            height: 16px;
            width: 16px;
        }
        
        label {
            font-size: 0.9rem;
            line-height: 1.5;
			margin-top: 0rem;
            margin-bottom: 0;
            display: inline;
            text-align: left;
        }
    }
    
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		width: 100%;
		padding: 0.75rem;
		border: 1px solid #ddd;
		border-radius: 4px;
		margin-bottom: 0.5rem;
		font-family: inherit;
		font-size: 1rem;
		
		&:focus {
			outline: none;
			border-color: #2E75B7;
			box-shadow: 0 0 0 2px rgba(46, 117, 183, 0.2);
		}
		
		@media (max-width: 768px) {
		    padding: 0.7rem;
		    font-size: 0.95rem;
		}
	}
	
	textarea {
		min-height: 150px;
		resize: vertical;
		
		@media (max-width: 768px) {
		    min-height: 120px;
		}
	}
    
    .cta-button {
        width: 100%;
        padding: 12px 24px;
        background-color: #2E75B7;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 1.1rem;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: 10px;
        
        &:hover {
            background-color: color.adjust(#2E75B7, $lightness: -10%);
        }
        
        @media (max-width: 768px) {
            padding: 10px 20px;
            font-size: 1rem;
        }
    }
}

// Add error field styles
.error-field {
    border-color: red !important;
    background-color: rgba(255, 0, 0, 0.05) !important;
}

.error-field[type="checkbox"] {
    outline: 2px solid red;
}

@media (max-width: 768px) {
    .contact-box {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }
}
